import { Form, InputGroup } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import InputField from '../inputField.js';
import PostcodeSelect from './postcodeSelect.js';
import { Button } from 'react-bootstrap';
import { useState, useRef } from 'react';
import PostCodeError from './postCodeError.js';
import { getAddresses_OS } from '../../../utils/sendRequest.js';

export default function PostcodeSearch({ label, ...props }) {
  const { values } = useFormikContext();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [noResults, setNoResults] = useState(false);
  const [error, setError] = useState();
  const postcodeSearch = useRef();

  const [state, setState] = useState({
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    addressLine4: '',
    postcode: '',
  });

  const addAddressToState = (address) => {
    let dpaDepartmentName = address.DPA.DEPARTMENT_NAME || '';
    let dpaOrganisationName = address.DPA.ORGANISATION_NAME || '';
    let dpaSubBuildingName = address.DPA.SUB_BUILDING_NAME || '';
    let dpaBuildingName = address.DPA.BUILDING_NAME || '';
    let dpaBuildingNumber = address.DPA.BUILDING_NUMBER || '';

    let dpaDependentThoroughfareName =
      address.DPA.DEPENDENT_THOROUGHFARE_NAME || '';
    let dpaThoroughfareName = address.DPA.THOROUGHFARE_NAME || '';
    let dpaDoubleDependentLocality =
      address.DPA.DOUBLE_DEPENDENT_LOCALITY || '';
    let dpaDependentLocality = address.DPA.DEPENDENT_LOCALITY || '';
    let dpaPostTown = address.DPA.POST_TOWN || '';
    let dpaPostcode = address.DPA.POSTCODE || '';

    //Dummy state update to refresh page

    setState((prevState) => ({
      ...prevState,
      addressLine1: address.DPA.BUILDING_NUMBER,
      addressLine2: address.DPA.THOROUGHFARE_NAME,
      addressLine3: address.DPA.LOCAL_CUSTODIAN_CODE_DESCRIPTION,
      addressLine4: address.DPA.POST_TOWN,
      postCode: address.DPA.POSTCODE,
    }));

    // Define arrays for the premises and thoroughfare components of the address, filter
    //to remove blanks
    let arrPremises = [
      dpaDepartmentName,
      dpaOrganisationName,
      dpaBuildingNumber,
      dpaSubBuildingName,
      dpaBuildingName,
    ].filter((item) => item !== '');
    let arrThoroughfareLocality = [
      dpaDependentThoroughfareName,
      dpaThoroughfareName,
      dpaDoubleDependentLocality,
      dpaDependentLocality,
    ].filter((item) => item !== '');

    setState((prevState) => ({
      ...prevState,
      addressLine1: arrPremises,
      addressLine2: arrThoroughfareLocality,
      addressLine3: '',
      addressLine4: dpaPostTown,
      postCode: dpaPostcode,
    }));

    values[`${props.addressFields}Line1`] = arrPremises.join(' ');
    values[`${props.addressFields}Line2`] = arrThoroughfareLocality.join(' ');
    values[`${props.addressFields}Line3`] = '';
    values[`${props.addressFields}Line4`] = dpaPostTown;
    values[`${props.addressFields}Postcode`] = dpaPostcode;
  };

  const handlePostCodeSearch = () => {
    const re =
      /^(([gG][iI][rR] {0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$/;

    if (re.test(postcodeSearch.current.value)) {
      setLoading(true);

      getAddresses_OS(postcodeSearch.current.value)
        .then((res) => {
          if (res.header.totalresults === 0) {
            setNoResults(true);
            setData(null);
            setError(null);
          } else {
            setNoResults(false);
            setData(res.results);
            setError(null);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setError('No address results found');
        });
    } else {
      //Fails regex postcode search value invalid
      setError('Please enter a valid postcode');
      setData(null);
    }
  };

  return (
    <>
      <Form.Group>
        <hr className="form-divider" />
        <Form.Label>{label}</Form.Label>
        <InputGroup>
          <Form.Control
            ref={postcodeSearch}
            disabled={
              loading ||
              (props.parentAddressDiffChild &&
                props.addressFields === 'parentAddress')
            }
            placeholder={props.placeholder}
          ></Form.Control>
          <Button
            variant={'outline-secondary'}
            disabled={
              loading ||
              (props.parentAddressDiffChild &&
                props.addressFields === 'parentAddress')
            }
            type="submit"
            onClick={handlePostCodeSearch}
          >
            Search
          </Button>
        </InputGroup>
      </Form.Group>

      {error && <PostCodeError error={error} />}
      {loading && <p>Loading...</p>}
      {data && data.length ? (
        <PostcodeSelect
          name={'AddressSelect'}
          label={'Select Address'}
          data={data}
          addressSelectedCallback={addAddressToState}
        />
      ) : (
        noResults && <PostCodeError error={'No address results found'} />
      )}
      <hr className="form-divider" />
      <Form.Group className="form-input-spacing">
        <InputField
          name={`${props.addressFields}Line1`}
          type="text"
          label={`${props.inputLabel} Line 1*`}
          disabled={
            loading ||
            (props.parentAddressDiffChild &&
              props.addressFields === 'parentAddress')
          }
        />
        <InputField
          name={`${props.addressFields}Line2`}
          type="text"
          label={`${props.inputLabel} Line 2*`}
          disabled={
            loading ||
            (props.parentAddressDiffChild &&
              props.addressFields === 'parentAddress')
          }
        />
        <InputField
          name={`${props.addressFields}Line3`}
          type="text"
          label={`${props.inputLabel} Line 3`}
          disabled={
            loading ||
            (props.parentAddressDiffChild &&
              props.addressFields === 'parentAddress')
          }
        />
        <InputField
          name={`${props.addressFields}Line4`}
          type="text"
          label={`${props.inputLabel} Line 4*`}
          disabled={
            loading ||
            (props.parentAddressDiffChild &&
              props.addressFields === 'parentAddress')
          }
        />
        <InputField
          name={`${props.addressFields}Postcode`}
          type="text"
          label={`${props.inputLabel} Postcode*`}
          disabled={
            loading ||
            (props.parentAddressDiffChild &&
              props.addressFields === 'parentAddress')
          }
        />
      </Form.Group>
    </>
  );
}
