import { Form } from 'react-bootstrap';
import { useField, useFormikContext } from 'formik';

export default function CheckboxField({ label, ...props }) {
  const [field, meta] = useField(props);

  const { values } = useFormikContext();

  const checkboxArray = values[props.name];

  return (
    <>
      <Form.Check
        {...field}
        {...props}
        type="checkbox"
        label={label}
        aria-required={true}
        checked={checkboxArray.includes(field.value.toString())} // Formik converts everything to strings!
        isInvalid={meta.touched && meta.error}
      ></Form.Check>
    </>
  );
}
