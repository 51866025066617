import React, { useState, useEffect } from 'react';
import * as FormValidator from '../../validation/index.js';
import Referrer from './referrer.js';
import Patient from './patient.js';
import Risk from './risk.js';
import ReferralReason from './referralReason.js';
import ErrorNotification from '../base/errorNotification.js';
import LoadingSpinner from '../base/loadingSpinner';
import { pdfReferenceValues } from '../../utils/pdfReferenceValues';
import { Row, Col, Alert, Card, Button } from 'react-bootstrap';
import {
  getParentalResponsibility,
  getEducationPlansReference,
  getGPPractice,
  getReferrerTitle,
  getNdtContactReference,
  getGenLanguages,
  getSchools,
  getGPRegistration,
  getCategory,
  getMannerismsReference,
  referral,
  bhamPostCodes,
  generatePDF,
} from '../../utils/sendRequest.js';

import Wizard from './wizardForm.js';
import moment from 'moment';

const WizardStep = ({ children }) => children;

export default function NDPForm() {
  const [eppRef, setEppRef] = useState();
  const [gPPractice, setGPPractice] = useState();
  const [school, setSchool] = useState();
  const [parentalResponsibility, setParentalResponsibility] = useState();
  const [gPRegistration, setGPRegistration] = useState();
  const [referrerTitle, setReferrerTitle] = useState();
  const [ndtContactRef, setNdtContactRef] = useState();
  const [genLanguages, setGenLanguages] = useState();
  const [mannerismsRef, setMannerismsRef] = useState();
  const [pdfFormValues, setPdfFormValues] = useState();
  const [category, setCategory] = useState();

  const [serverError, setServerError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState();

  const [patientName, setPatientName] = useState('');

  const pdfDownload = async () => {
    const pdf = pdfReferenceValues(
      pdfFormValues,
      parentalResponsibility,
      referrerTitle,
      ndtContactRef,
      genLanguages,
      school,
      gPPractice,
      eppRef,
      gPRegistration,
      category,
      mannerismsRef,
    );

    setProcessing(true);

    try {
      const resPdf = await generatePDF(pdf);

      const file = new Blob([resPdf], { type: 'application/pdf' });
      //IE fix
      // window navigator - detect browser
      //msSaveOrOpenBlob ie method to save a file in a browser
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(file);
        return;
      }

      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = fileURL;
      link.download = `NDP-Referral ${pdfFormValues.inputDate}.pdf`;
      link.click();
      setTimeout(() => {
        URL.revokeObjectURL(link);
        setProcessing(false);
        setFailed(null);
      }, 100);
    } catch (error) {
      setProcessing(false);
      setFailed(error);
    }
  };

  const checkBhamPostCodes = async (postCode) => {
    setProcessing(true);

    try {
      const res = await bhamPostCodes({ postCode: postCode });
      setProcessing(false);
      setFailed(null);
      return res;
    } catch (error) {
      setProcessing(false);
      setFailed(error);
    }
  };

  useEffect(() => {
    async function getFormOptions() {
      try {
        const [
          parentalResponsibility,
          referrerTitle,
          ndtContactRef,
          genLanguages,
          schools,
          gpPractices,
          eppRef,
          gPRegistration,
          category,
          mannerismsRef,
        ] = await Promise.all([
          getParentalResponsibility(),
          getReferrerTitle(),
          getNdtContactReference(),
          getGenLanguages(),
          getSchools(),
          getGPPractice(),
          getEducationPlansReference(),
          getGPRegistration(),
          getCategory(),
          getMannerismsReference(),
        ]);
        setParentalResponsibility(parentalResponsibility);
        setReferrerTitle(referrerTitle);
        setNdtContactRef(ndtContactRef);
        setGenLanguages(genLanguages);
        setGPPractice(gpPractices);
        setGPRegistration(gPRegistration);
        setSchool(schools);
        setEppRef(eppRef);
        setCategory(category);
        setMannerismsRef(mannerismsRef);
        setLoading(false);
      } catch (err) {
        setServerError(err);
        setParentalResponsibility(null);
        setReferrerTitle(null);
        setNdtContactRef(null);
        setGenLanguages(null);
        setGPPractice(null);
        setGPRegistration(null);
        setSchool(null);
        setEppRef(null);
        setCategory(null);
        setMannerismsRef(null);
        setLoading(false);
      }
    }
    getFormOptions();
  }, []);

  if (loading) {
    return (
      <Row>
        <Col xs={12} sm={12} md={3}></Col>
        <Col xs={12} sm={12} md={9}>
          <LoadingSpinner />
        </Col>
      </Row>
    );
  } else {
    if (serverError) {
      return <ErrorNotification alertMessage={serverError.message} />;
    } else {
      if (success) {
        return (
          <Row>
            <Col xs={12} sm={12} md={12}>
              <>
                {failed && (
                  <Alert variant="danger">Error downloading NDP PDF</Alert>
                )}
                <Alert variant="success">
                  <Alert.Heading>{`${patientName} - NDP form successfuly submitted`}</Alert.Heading>
                  <p>
                    This referral has been submitted for triage by the
                    Neurodevelopmental Pathway clinical team. You will be
                    notified by email to confirm whether the referral has been
                    accepted for screening and assessment. If the child is
                    accepted on to the Neurodevelopmental Pathway then the
                    child’s educational setting will be contacted for further
                    information.
                  </p>
                  <hr />
                  <p>
                    Unfortunately there are waiting times for these assessments
                    but we are working hard to reduce them, thank you for your
                    cooperation.
                  </p>
                  <hr />
                  <p className="mb-0">
                    Please refresh the page to submit another referral.
                  </p>
                  <Button
                    className="mt-3"
                    onClick={pdfDownload}
                    disabled={processing}
                  >
                    Download Referral PDF
                  </Button>
                </Alert>
              </>
            </Col>
          </Row>
        );
      } else {
        return (
          <>
            {failed && (
              <Row>
                <Col xs={12} sm={12} md={3}></Col>
                <Col xs={12} sm={12} md={9}>
                  <Card bg="danger" text="light" className="mb-3">
                    <Card.Header>Error</Card.Header>
                    <Card.Body>
                      {`There is a problem - error completing request. ${failed.message}.`}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            )}
            <Wizard
              initialValues={{
                //1
                referrerName: '',
                parentalResponibilityID: '',
                referrerTitleID: '',
                referrerEmail: '',
                referrerTelephone: '',
                referrerMobile: '',
                referrerAddressLine1: '',
                referrerAddressLine2: '',
                referrerAddressLine3: '',
                referrerAddressLine4: '',
                referrerAddressPostcode: '',
                referrerConsent: 'Yes',
                referrerConsentDate: '',
                referrerNdtContactConsent: 'Yes',
                referrerNdtContact: '',
                referrerNdtContactOther: '',

                //2
                severeLearningDisability: 'No',
                childFirstName: '',
                childSurname: '',
                childDOB: '',
                childAddressLine1: '',
                childAddressLine2: '',
                childAddressLine3: '',
                childAddressLine4: '',
                childAddressPostcode: '',
                nhsNumber: '',
                parentName: '',
                parentEmail: '',
                parentTelephone: '',
                parentAddressDiffChild: 'No',
                parentAddressLine1: '',
                parentAddressLine2: '',
                parentAddressLine3: '',
                parentAddressLine4: '',
                parentAddressPostcode: '',
                interpreter: 'No',
                interpreterGenLanguageCode: '',
                gPRegistrationID: '',
                gPPracticeID: '',
                inSchool: 'Yes',
                homeEducation: 'No',
                schoolID: '',
                schoolNameOther: '',
                schoolNurseryAddress: '',
                schoolNurseryContactPerson: '',
                schoolNurseryContactPersonTel: '',
                schoolEmail: '',
                schoolTelephone: '',

                //3
                childTheirSafetyID: '',
                childSafetyOfOthersID: '',
                childExclusionID: '',
                childLACareID: '',
                childAdoptionID: '',
                childProtectionPlanID: '',
                childInNeedPlanID: '',
                childReferredMashID: '',
                educationPlansInPlace: '',
                referralReason: '',
                //4 Additional Information
                socialInteractionEyeID: '',
                socialInteractionGesturesID: '',
                socialInteractionEmotionsID: '',
                restrictiveBehaviourInterestID: '',
                restrictiveBehaviourUnusualID: '',
                restrictiveBehaviourRoutineID: '',
                restrictiveBehaviourDistressID: '',
                communicationUnderstandID: '',
                communicationSpokenID: '',
                communicationFollowsID: '',
                communicationExpressionsID: '',
                attentionSelfID: '',
                attentionLedID: '',
                attentionLevelID: '',
                sensoryLoudID: '',
                sensoryTasteID: '',
                learningBehindID: '',
                learningSettledID: '',
                visionPassedID: '',
                visionConcernID: '',
                hearingPassedID: '',
                hearingConcernID: '',
                motorPassedID: '',
                motorConcernID: '',
                behaviourID: '',
                mannerisms: '',
                mannerismsComment: '',
                motorLostID: '',
                motorLostComment: '',
                birthID: '',
                learningDelayID: '',
                learningDelayComment: '',
                autFamilyID: '',
                autFamilyComment: '',
                additionalMedicalID: '',
                additionalMedicalComment: '',

                additionalInformation: '',
              }}
              onSubmit={async (values) => {
                setPatientName(
                  values.childFirstName.concat(' ', values.childSurname),
                );
                setProcessing(true);
                try {
                  const resInputDate = await referral(values);
                  setProcessing(false);
                  setSuccess(true);
                  setFailed(null);
                  values['inputDate'] = moment(resInputDate)
                    .utc()
                    .format('DD-MM-YYYY HH:mm:ss'); //PDF timestamp
                  setPdfFormValues(values);
                } catch (error) {
                  setProcessing(false);
                  setFailed(error);
                }
              }}
              checkBhamPostCodes={checkBhamPostCodes}
            >
              <WizardStep validationSchema={FormValidator.referrer}>
                <Referrer
                  processing={processing}
                  parentalResponsibility={parentalResponsibility}
                  referrerTitle={referrerTitle}
                  ndtContactReference={ndtContactRef}
                />
              </WizardStep>
              <WizardStep validationSchema={FormValidator.patient}>
                <Patient
                  processing={processing}
                  genLanguages={genLanguages}
                  school={school}
                  gPRegistration={gPRegistration}
                  gPPractice={gPPractice}
                />
              </WizardStep>
              <WizardStep validationSchema={FormValidator.risk}>
                <Risk
                  processing={processing}
                  category={category}
                  eppRef={eppRef}
                />
              </WizardStep>
              <WizardStep validationSchema={FormValidator.referralReason}>
                <ReferralReason
                  processing={processing}
                  category={category}
                  mannerisms={mannerismsRef}
                />
              </WizardStep>
            </Wizard>
          </>
        );
      }
    }
  }
}
