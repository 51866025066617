const { hostname, protocol } = window.location;
const host =
  process.env.NODE_ENV === 'production'
    ? `https://api.bchcreferrals.nhs.uk`
    : `${protocol}//${hostname}:8081`;

function send(path, token, model, method) {
  const url = host + path;

  const headers = {
    'Content-Type': 'application/json',
  };

  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  const request = {
    credentials: 'omit',
    method: method === 'POST' ? 'POST' : 'GET',
    headers,
    referrer: 'no-referrer',
  };

  if (model) {
    request['body'] = JSON.stringify(model);
  }

  return new Promise((resolve, reject) => {
    fetch(url, request)
      .then((res) => {
        if (res.status === 200) {
          return res.json().then(resolve).catch(reject);
        } else if (res.status === 204) {
          return resolve(null);
        } else {
          return res.json().then(reject).catch(reject);
        }
      })
      .catch(reject);
  });
}

function pdfSend(path, token, model, method) {
  const url = host + path;

  const headers = {
    'Content-Type': 'application/json',
  };

  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  const request = {
    credentials: 'omit',
    method: method === 'POST' ? 'POST' : 'GET',
    headers,
    referrer: 'no-referrer',
  };

  if (model) {
    request['body'] = JSON.stringify(model);
  }

  return new Promise((resolve, reject) => {
    fetch(url, request)
      .then((res) => {
        if (res.status === 200) {
          return res.blob().then(resolve).catch(reject);
        } else if (res.status === 204) {
          return resolve(null);
        } else {
          return res.json().then(reject).catch(reject);
        }
      })
      .catch(reject);
  });
}

// categories

export function referral(values) {
  return send('/referrer', null, values, 'POST');
}

export function getParentalResponsibility() {
  return send('/parental-responsibility', null, null, 'GET');
}

export function getReferrerTitle() {
  return send('/referrer-title', null, null, 'GET');
}

export function getNdtContactReference() {
  return send('/ndt-contact-reference', null, null, 'GET');
}

export function getGenLanguages() {
  return send('/genLanguage', null, null, 'GET');
}

export function getGPPractice() {
  return send('/gPPractice', null, null, 'GET');
}

export function getSchools() {
  return send('/schools', null, null, 'GET');
}

export function getEducationPlansReference() {
  return send('/education-plans-in-place-reference', null, null, 'GET');
}

export function getCategory() {
  return send('/category', null, null, 'GET');
}

export function getGPRegistration() {
  return send('/gPRegistration', null, null, 'GET');
}

export function bhamPostCodes(value) {
  return send('/bhamPostCodes', null, value, 'POST');
}

export function getMannerismsReference() {
  return send('/mannerisms-reference', null, null, 'GET');
}

export function generatePDF(value) {
  return pdfSend('/pdf-download', null, value, 'POST');
}

export function generatePDFQuestions(value) {
  return pdfSend('/pdf-download-questions', null, value, 'POST');
}

export function getAddresses_OS(postcode) {
  return send('/getOSAddressResults', null, { postcode }, 'POST');
}
