import * as yup from 'yup';

import { intRequired, arrayContains, stringRequired } from '../fields/index.js';

export default yup.object().shape({
  childTheirSafetyID: intRequired('This information is required'),
  childSafetyOfOthersID: intRequired('This information is required'),
  childExclusionID: intRequired('This information is required'),
  childLACareID: intRequired('This information is required'),
  childAdoptionID: intRequired('This information is required'),
  childProtectionPlanID: intRequired('This information is required'),
  childInNeedPlanID: intRequired('This information is required'),
  childReferredMashID: intRequired('This information is required'),
  educationPlansInPlace: arrayContains('This information is required'),
  // otherDiagnosis: arrayContains('Other health conditions'),
  // otherDiagnosisComment: yup.string().when('otherDiagnosis', {
  //   is: (otherDiagnosis) =>
  //     // 23 is other
  //     otherDiagnosis && otherDiagnosis.includes('23'),
  //   then: stringRequired('Selected other - other health condition detail'),
  // }),
  referralReason: stringRequired('This information is required'),
});
