import { Form } from 'react-bootstrap';
import { useField } from 'formik';

export default function TextAreaField({ label, ...props }) {
  const [field, meta] = useField(props);
  return (
    <Form.Group className="form-input-spacing" controlId={props.name}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        {...field}
        {...props}
        aria-required={true}
        isInvalid={meta.touched && meta.error}
      ></Form.Control>
      <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
    </Form.Group>
  );
}
