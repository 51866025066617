import { Button, Table } from 'react-bootstrap';

export default function OpeningPage({
  pdfQuestionsDownload,
  pdfQuestionsProcessing,
}) {
  return (
    <>
      <h1 className="mt-2 mb-2">Neurodevelopmental Pathway: Referral Form</h1>

      <div className="mb-2">
        <h4> Criteria for form submission</h4>
        <p className="mb-0">
          In order to successfully complete and submit an ASD web referral for
          triage by the NDP service the following minimum criteria must be met
          in the form.
        </p>
        <ul className="mb-0">
          <li>
            Referred child is aged between 2 years and 6 months old and 15 years
            and 364 days old. <strong>AND</strong>
          </li>
          <li>
            They <strong>do not</strong> have a <strong>severe</strong> learning
            disability. <strong>AND</strong>
          </li>
          <li>
            The referral is made by a health, education or social care
            professional (referrals by parents are not accepted).{' '}
            <strong>AND</strong>
          </li>
          <li>
            All other mandatory questions are answered. <strong>AND</strong>
          </li>
          <ul>
            <li>
              They are registered with a Birmingham GP. <strong>OR</strong>
            </li>
            <li>
              They are not registered with any GP but are resident in the
              Birmingham Local Authority area.
            </li>
          </ul>
        </ul>
        <a
          href="https://www.bhamcommunity.nhs.uk/patients-public/children-and-young-people/services-parent-portal/neurodevelopmental-pathway/"
          target="_blank"
          rel="noreferrer"
        >
          For further information please visit the BCHC Neurodevelopmental
          Pathway web page
        </a>

        <p className="mt-2 mb-2">
          <strong>IMPORTANT INFORMATION FOR REFERRERS:</strong> The Birmingham
          Community Healthcare NHS Trust's Neurodevelopmental Pathway (NDP)
          service uses a secure on-line referral form to ensure that referrals
          for assessment of Autism Spectrum Disorder contain all necessary
          information to enable clinical triage and assessment. The majority of
          questions in the form are mandatory and the form can not be submitted
          unless all these are answered. Please review the information below
          before referring to ensure you have all necessary information ready to
          complete the form.
        </p>
        <p className="mt-2 mb-1">
          If you would like to export this list for the parent to complete some
          information before referring please click the export question list
          button below.
        </p>
        <div className="mb-2">
          <Button
            variant="secondary"
            type="button"
            onClick={async () => await pdfQuestionsDownload()}
            disabled={pdfQuestionsProcessing}
          >
            Export questions list
          </Button>
        </div>
        <p>
          If you encounter any technical issue which is preventing you from
          accessing or completing the form please contact the Neurodevelopmental
          Pathway’s Central Booking Service on 0121 683 2320 (option 4) and one
          of the team will assist.
        </p>
      </div>
      <Table responsive="lg">
        <thead>
          <tr>
            <th>Field Name</th>
            <th>Mandatory</th>
            <th>Further information</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Referrer Name</td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>Person with Parental Responsibility</td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>Referrer Title/Position</td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>Referrer Email</td>
            <td>Y</td>
            <td>
              This enables future communications with referrer with respect to
              status of referral
            </td>
          </tr>
          <tr>
            <td>Referrer Telephone</td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>Referrer Mobile</td>
            <td>N</td>
            <td>Mandatory if no referrer telephone provided</td>
          </tr>
          <tr>
            <td>Referrer Address</td>
            <td>Y</td>
            <td>Address can be selected through a postcode search</td>
          </tr>
          <tr>
            <td>Referrer Consent</td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>Referrer Consent Date</td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>
              Does the person with parental responsibility give permission for
              the Neurodevelopmental Team to contact the following services for
              further information
            </td>
            <td>Y</td>
            <td>
              <strong>
                In order for assessment to proceed it is essential that
                permission is given to contact school or education provider
              </strong>{' '}
              as they will need to complete questionnaires for assessment
            </td>
          </tr>
          <tr>
            <td>Does the child have a severe learning disability?</td>
            <td>Y</td>
            <td>
              If the child has a <strong>severe learning disability</strong>{' '}
              they can not be assessed by the NDP service and should be referred
              to an alternative provider
            </td>
          </tr>
          <tr>
            <td>Child's First Name</td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>Child's Last Name</td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>Child's DOB</td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>Child's Address</td>
            <td>Y</td>
            <td>Address can be selected through a postcode search</td>
          </tr>
          <tr>
            <td>NHS No. (if known)</td>
            <td>N</td>
            <td></td>
          </tr>
          <tr>
            <td>Parent Name</td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>Parent / Carer’s email address</td>
            <td>N</td>
            <td></td>
          </tr>
          <tr>
            <td>Parent / Carer’s phone number</td>
            <td>N</td>
            <td></td>
          </tr>
          <tr>
            <td>Parent/Current Carer Address if different to child</td>
            <td>Y</td>
            <td>Address can be selected through a postcode search</td>
          </tr>
          <tr>
            <td>Interpreter required</td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>Interpreter language</td>
            <td>N</td>
            <td></td>
          </tr>
          <tr>
            <td>Is child in school/nursery setting</td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>Is child in home education</td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>Child School/Nursery if in school/nursery setting</td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>School/Nursery Name Other</td>
            <td>N</td>
            <td>Mandatory if 'Other' selected for child school/nursery</td>
          </tr>
          <tr>
            <td>School/Nursery Address</td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>School/Nursery Contact Person</td>
            <td>N</td>
            <td rowSpan={4}>
              In order to facilitate completion of assessment please provide
              contact details so school or education provider can be contacted
              to complete questionnaires necessary for assessment
            </td>
          </tr>
          <tr>
            <td>School/Nursery Contact Person Telephone</td>
            <td>N</td>
          </tr>
          <tr>
            <td>School/Nursery Email</td>
            <td>N</td>
          </tr>
          <tr>
            <td>School/Nursery Telephone</td>
            <td>N</td>
          </tr>

          <tr>
            <td>Is child registered with a GP</td>
            <td>Y</td>
            <td rowSpan={2}>
              If the child is registered with a GP this{' '}
              <strong>must be a Birmingham Practice.</strong> If the child is
              registered with a practice outside of Birmingham the referral can
              not be submitted
            </td>
          </tr>
          <tr>
            <td>Child GP</td>
            <td>Y</td>
          </tr>
          <tr>
            <td>
              The child's behaviour endangers their safety, in a way that cannot
              be managed
            </td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>
              The child's behaviour endangers the safety of others in a way that
              cannot be managed
            </td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>
              The child's school / nursery has already or is considering
              excluding or expelling them
            </td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>
              Is the child in the care of the Local Authority (a Child in Care)
            </td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>The child is being considered for adoption</td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>Is there a child protection plan in place</td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>Is there a child in need plan for the child</td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>Is the child referred to Safeguarding Children Services</td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>Education Plans in Place</td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>Reason for referral</td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>
              Compared to other children of their age the child uses eye contact
            </td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>
              Compared to other children of their age the child uses gesture to
              share their intentions
            </td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>
              Compared to other children of their age the child shares their
              emotions (excitement, pain, fear, happiness)
            </td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>
              The child has a specific area of interest that is more intense or
              detailed than other children of their age
            </td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>
              The child has a specific area of interest that is unusual compared
              to other children of their age
            </td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>
              The child has specific routines they need to keep to throughout
              the day
            </td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>
              The child has routines or rituals that cause them a level of
              distress if they are not completed
            </td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>The child understands and responds to spoken language</td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>The child uses spoken language</td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>The child follows and joins in conversations</td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>
              The child understands and uses facial expressions, tone of voice
              and body language
            </td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>
              The child can sustain their attention to a self chosen task for
            </td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>
              The child can sustain their attention to an adult led task for
            </td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>
              The child can adjust their activity levels to the social context
            </td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>The child responds to loud sounds or noisy environments</td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>The child responds to tastes and textures</td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>Child's learning</td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>The child has settled into school / nursery</td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>Has the child had a sight test</td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>Do you have concerns about their sight</td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>Has the child passed a hearing test</td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>Do you have concerns about their hearing</td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>
              Do you have concerns about the child's fine motor skills (writing,
              doing up buttons, zips)
            </td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>
              Do you have concerns about the child's gross motor skills (walking
              running jumping)
            </td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>Child's behaviour</td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>Does the child show any stereotypical mannerisms / stimming</td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>
              Has the child ever lost any motor skills? (e.g. walking skills
              worsened significantly or lost ability to walk after achieving
              this)
            </td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>Does the child have more than 3 birth marks</td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>Does the child have moderate to severe learning delay</td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>Is there a family history of Autism/ADHD/learning delay</td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>Any additional medical concerns</td>
            <td>Y</td>
            <td></td>
          </tr>
          <tr>
            <td>Additional Information</td>
            <td>N</td>
            <td></td>
          </tr>
        </tbody>
      </Table>
    </>
  );
}
