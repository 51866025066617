import { Form } from 'react-bootstrap';
import SelectField from '../formFields/selectField.js';
import TextAreaField from '../formFields/textAreaField.js';
import CheckBoxField from '../formFields/checkBoxField.js';
import InputField from '../formFields/inputField.js';
import CustomFieldError from '../formFields/customFieldError.js';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';

export default function ReferralReason(props) {
  const { errors, touched, values } = useFormikContext();

  useEffect(() => {
    window.scrollTo(0, 20); // 20 prevent scrolling issues, not firing on build
  }, []);

  const filterFreq = props.category.filter(
    (x) => x.CategoriesGroup === 'SOCIAL',
  );

  const filterYesNoHas = props.category.filter(
    (x) => x.CategoriesGroup === 'YESNO' && x.ValueCode !== 'NotKnown',
  );

  const filterYesNo = props.category.filter(
    (x) =>
      x.CategoriesGroup === 'YESNO' &&
      x.ValueCode !== 'NotKnown' &&
      x.ValueCode !== 'Past',
  );

  const filterCommm = props.category.filter(
    (x) =>
      x.CategoriesGroup === 'COMM' &&
      x.ValueCode !== 'NotUsed' &&
      x.ValueCode !== 'NotFollow' &&
      x.ValueCode !== 'NotExpression',
  );

  const filterCommmSpokenLanguage = props.category.filter(
    (x) =>
      x.CategoriesGroup === 'COMM' &&
      x.ValueCode !== 'NoUnderstanding' &&
      x.ValueCode !== 'NotFollow' &&
      x.ValueCode !== 'NotExpression',
  );

  const filterCommmConversations = props.category.filter(
    (x) =>
      x.CategoriesGroup === 'COMM' &&
      x.ValueCode !== 'NoUnderstanding' &&
      x.ValueCode !== 'NotUsed' &&
      x.ValueCode !== 'NotExpression',
  );

  const filterCommmExpressions = props.category.filter(
    (x) =>
      x.CategoriesGroup === 'COMM' &&
      x.ValueCode !== 'NoUnderstanding' &&
      x.ValueCode !== 'NotUsed' &&
      x.ValueCode !== 'NotFollow',
  );

  const filterPeers = props.category.filter(
    (x) =>
      x.CategoriesGroup === 'ATTENTION' &&
      x.ValueCode !== 'CannotSustainAdult' &&
      x.ValueCode !== 'ActivityLevels',
  );

  const filterPeersAdult = props.category.filter(
    (x) =>
      x.CategoriesGroup === 'ATTENTION' &&
      x.ValueCode !== 'CannotSustain' &&
      x.ValueCode !== 'ActivityLevels',
  );

  const filterPeersActivity = props.category.filter(
    (x) =>
      x.CategoriesGroup === 'ATTENTION' &&
      x.ValueCode !== 'CannotSustainAdult' &&
      x.ValueCode !== 'CannotSustain',
  );

  const filterSensory = props.category.filter(
    (x) => x.CategoriesGroup === 'SENSORY',
  );

  const filterLearning = props.category.filter(
    (x) => x.CategoriesGroup === 'LEARNING',
  );

  const filterSchool = props.category.filter(
    (x) => x.CategoriesGroup === 'SCHLEARNING',
  );

  const filterYesNoNotKnown = props.category.filter(
    (x) => x.CategoriesGroup === 'YESNO' && x.ValueCode !== 'Past',
  );

  const filterBehaviour = props.category.filter(
    (x) => x.CategoriesGroup === 'BEHAVIOUR',
  );

  return (
    <>
      <Form.Group>
        <h4>Social Interaction</h4>
        <SelectField
          name={'socialInteractionEyeID'}
          label={
            'Compared to other children of their age the child uses eye contact*'
          }
          data={filterFreq}
          disabled={props.processing}
        />
        <SelectField
          name={'socialInteractionGesturesID'}
          label={
            'Compared to other children of their age the child uses gesture to share their intentions*'
          }
          data={filterFreq}
          disabled={props.processing}
        />
        <SelectField
          name={'socialInteractionEmotionsID'}
          label={
            'Compared to other children of their age the child shares their emotions (excitement, pain, fear, happiness)*'
          }
          data={filterFreq}
          disabled={props.processing}
        />
      </Form.Group>
      <Form.Group>
        <h4>Restrictive Pattern of Behaviours</h4>
        <SelectField
          name={'restrictiveBehaviourInterestID'}
          label={
            'The child has a specific area of interest that is more intense or detailed than other children of their age*'
          }
          data={filterYesNoHas}
          disabled={props.processing}
        />
        <SelectField
          name={'restrictiveBehaviourUnusualID'}
          label={
            'The child has a specific area of interest that is unusual compared to  other children of their age*'
          }
          data={filterYesNoHas}
          disabled={props.processing}
        />
        <SelectField
          name={'restrictiveBehaviourRoutineID'}
          label={
            'The child has specific routines they need to keep to throughout the day*'
          }
          data={filterYesNoHas}
          disabled={props.processing}
        />
        <SelectField
          name={'restrictiveBehaviourDistressID'}
          label={
            'The child has routines or rituals that cause them a level of distress if they are not completed*'
          }
          data={filterYesNoHas}
          disabled={props.processing}
        />
      </Form.Group>
      <Form.Group>
        <h4>Communication</h4>
        <SelectField
          name={'communicationUnderstandID'}
          label={'The child understands and responds to spoken language*'}
          data={filterCommm}
          disabled={props.processing}
        />
        <SelectField
          name={'communicationSpokenID'}
          label={'The child uses spoken language*'}
          data={filterCommmSpokenLanguage}
          disabled={props.processing}
        />
        <SelectField
          name={'communicationFollowsID'}
          label={'The child follows and joins in conversations*'}
          data={filterCommmConversations}
          disabled={props.processing}
        />
        <SelectField
          name={'communicationExpressionsID'}
          label={
            'The child understands and uses facial expressions, tone of voice and body language*'
          }
          data={filterCommmExpressions}
          disabled={props.processing}
        />
      </Form.Group>
      <Form.Group>
        <h4>Attention and Listening</h4>
        <SelectField
          name={'attentionSelfID'}
          label={
            'The child can sustain their attention to a self chosen task for*'
          }
          data={filterPeers}
          disabled={props.processing}
        />
        <SelectField
          name={'attentionLedID'}
          label={
            'The child can sustain their attention to an adult led task for*'
          }
          data={filterPeersAdult}
          disabled={props.processing}
        />
        <SelectField
          name={'attentionLevelID'}
          label={
            'The child can adjust their activity levels to the social context*'
          }
          data={filterPeersActivity}
          disabled={props.processing}
        />
      </Form.Group>
      <Form.Group>
        <h4>Sensory Processing</h4>
        <SelectField
          name={'sensoryLoudID'}
          label={'The child responds to loud sounds or noisy environments*'}
          data={filterSensory}
          disabled={props.processing}
        />
        <SelectField
          name={'sensoryTasteID'}
          label={'The child responds to tastes and textures*'}
          data={filterSensory}
          disabled={props.processing}
        />
      </Form.Group>
      <Form.Group>
        <h4>Learning</h4>
        <SelectField
          name={'learningBehindID'}
          label={"The child's learning is*"}
          data={filterLearning}
          disabled={props.processing}
        />
        <SelectField
          name={'learningSettledID'}
          label={'The child has settled into school / nursery*'}
          data={filterSchool}
          disabled={props.processing}
        />
      </Form.Group>
      <Form.Group>
        <h4>Vision</h4>
        <SelectField
          name={'visionPassedID'}
          label={'Has the child had a sight test*'}
          data={filterYesNoNotKnown}
          disabled={props.processing}
        />
        <SelectField
          name={'visionConcernID'}
          label={'Do you have concerns about their sight*'}
          data={filterYesNoNotKnown}
          disabled={props.processing}
        />
      </Form.Group>
      <Form.Group>
        <h4>Hearing</h4>
        <SelectField
          name={'hearingPassedID'}
          label={'Has the child passed a hearing test*'}
          data={filterYesNoNotKnown}
          disabled={props.processing}
        />
        <SelectField
          name={'hearingConcernID'}
          label={'Do you have concerns about their hearing*'}
          data={filterYesNoNotKnown}
          disabled={props.processing}
        />
      </Form.Group>
      <Form.Group>
        <h4>Motor Skills</h4>
        <SelectField
          name={'motorPassedID'}
          label={
            "Do you have concerns about the child's fine motor skills (writing, doing up buttons, zips)*"
          }
          data={filterYesNoNotKnown}
          disabled={props.processing}
        />
        <SelectField
          name={'motorConcernID'}
          label={
            "Do you have concerns about the child's gross motor skills (walking running jumping)*"
          }
          data={filterYesNoNotKnown}
          disabled={props.processing}
        />
      </Form.Group>
      <Form.Group>
        <h4>Behaviour</h4>
        <SelectField
          name={'behaviourID'}
          label={"The child's behaviour is*"}
          data={filterBehaviour}
          disabled={props.processing}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <h4>Stereotypical Mannerisms</h4>
        <Form.Check.Label className="mb-2">
          Does the child show any stereotypical mannerisms / stimming*
        </Form.Check.Label>

        {props.mannerisms.map((item, index) => (
          <CheckBoxField
            key={index}
            name={`mannerisms`}
            value={item.Id}
            label={item.Description}
            id={item.Description}
            type="checkbox"
            disabled={
              props.processing ||
              (item.Id === 6 &&
                values.mannerisms.length > 0 &&
                !values.mannerisms.includes('6')) ||
              (item.Id !== 6 &&
                values.mannerisms.length > 0 &&
                values.mannerisms.includes('6'))
            }
          />
        ))}

        <CustomFieldError name={'mannerisms'} />
      </Form.Group>
      <TextAreaField
        name="mannerismsComment"
        disabled={props.processing || !values.mannerisms.includes('7')} //7 is other
        as="textarea"
        rows={3}
        label="Other mannerisms not listed"
      />
      <h4>Other Medical Needs</h4>
      <SelectField
        name={'motorLostID'}
        label={
          'Has the child ever lost any motor skills? (e.g. walking skills worsened significantly or lost ability to walk after achieving this)*'
        }
        data={filterYesNoNotKnown}
        disabled={props.processing}
      />
      <InputField
        name="motorLostComment"
        disabled={props.processing || values.motorLostID !== 1}
        type="text"
        label="If yes, please give details"
      />
      <SelectField
        name={'birthID'}
        label={'Does the child have more than 3 birth marks?*'}
        data={filterYesNoNotKnown}
        disabled={props.processing}
      />
      <SelectField
        name={'learningDelayID'}
        label={'Does the child have moderate to severe learning delay?*'}
        data={filterYesNoNotKnown}
        disabled={props.processing}
      />
      <InputField
        name="learningDelayComment"
        disabled={props.processing || values.learningDelayID !== 1}
        type="text"
        label="If yes, please give details"
      />
      <SelectField
        name={'autFamilyID'}
        label={
          'Is there a family history of Autism/ADHD/learning delay? e.g. sibling / parent / 1st cousin / uncle / aunt*'
        }
        data={filterYesNoNotKnown}
        disabled={props.processing}
      />
      <InputField
        name="autFamilyComment"
        disabled={props.processing || values.autFamilyID !== 1}
        type="text"
        label="If yes, please give details"
      />
      <SelectField
        name={'additionalMedicalID'}
        label={'Any additional medical concerns or conditions?*'}
        data={filterYesNo}
        disabled={props.processing}
      />
      <InputField
        name="additionalMedicalComment"
        disabled={props.processing || values.additionalMedicalID !== 1}
        type="text"
        label="If yes, please give details"
      />

      <hr className="form-divider" />
      <TextAreaField
        name="additionalInformation"
        disabled={props.processing}
        as="textarea"
        rows={5}
        label="Additional Information"
      />
      {Object.keys(touched).length > 0 && Object.keys(errors).length > 0 && (
        <p className="customError mt-4">
          Please complete all required fields (*)
        </p>
      )}
    </>
  );
}
