import * as yup from 'yup';
import moment from 'moment';

import {
  stringRequired,
  stringMinMaxRequired,
  nullableString,
  nullableStringMax,
  stringRequiredWhenValue,
  detailsString,
  nullableNhsNo,
  nullableEmail,
  postcode,
  nullableTelephone,
} from '../fields/index.js';

export default yup.object().shape({
  severeLearningDisability: stringRequiredWhenValue(
    'Children with severe learning disabilities are not assessed by the BCHC NDP service.',
    'No',
  ),
  childFirstName: stringMinMaxRequired('This information is required', 1, 255),
  childSurname: stringMinMaxRequired('This information is required', 1, 255),

  childDOB: yup
    .date()
    .test({
      message:
        'Child referred to this pathway needs to be aged between 2.5 and 15 years and 364 days old',
      test: function (value) {
        if (
          value &&
          moment().diff(moment(value), 'years') < 16 &&
          moment().diff(moment(value), 'months') >= 30
        ) {
          return true;
        } else {
          return false;
        }
      },
    })
    .typeError(
      'Child referred to this pathway needs to be aged between 2.5 and 15 years and 364 days old',
    ),

  childAddressLine1: stringMinMaxRequired(
    'This information is required',
    1,
    500,
  ),
  childAddressLine2: stringMinMaxRequired(
    'This information is required',
    1,
    500,
  ),
  childAddressLine3: nullableStringMax('Incorrect format', 500),
  childAddressLine4: stringMinMaxRequired(
    'This information is required',
    1,
    500,
  ),
  childAddressPostcode: postcode(
    'This information is required. Please enter a valid postcode',
  ),
  nhsNumber: nullableNhsNo('Please enter a valid NHS number'),
  parentName: stringMinMaxRequired('This information is required', 1, 500),
  parentEmail: nullableEmail('Please enter a valid email address'),
  parentTelephone: nullableTelephone('Please enter a valid telephone'),
  parentAddressDiffChild: stringRequired(
    'Parent address different to child, this information is required',
  ),
  parentAddressLine1: detailsString(
    'parentAddressDiffChild',
    stringMinMaxRequired('This information is required', 1, 500),
    'Yes',
  ),
  parentAddressLine2: detailsString(
    'parentAddressDiffChild',
    stringMinMaxRequired('This information is required', 1, 500),
    'Yes',
  ),
  parentAddressLine3: detailsString(
    'parentAddressDiffChild',
    nullableStringMax('Incorrect format', 500),
    'Yes',
  ),
  parentAddressLine4: detailsString(
    'parentAddressDiffChild',
    stringMinMaxRequired('This information is required', 1, 500),
    'Yes',
  ),
  parentAddressPostcode: detailsString(
    'parentAddressDiffChild',
    postcode('Please enter a valid postcode'),
    'Yes',
  ),
  interpreter: stringRequired('This information is required'),
  interpreterGenLanguageCode: detailsString(
    'interpreter',
    stringRequired('Selected YES. This information is required'),
    'Yes',
  ),
  gPRegistrationID: yup.number().test({
    test: function (value) {
      if (value === 1) {
        return true;
      }
      if (value === 2) {
        return this.createError({
          path: 'gPRegistrationID',
          message:
            'The BCHC neurodevelopmental pathway service can only accept referrals for children registered with Birmingham GPs.  Please liaise with the child’s GP to identify appropriate services in their area.',
        });
      } else if (value === 3) {
        return true;
        // return this.createError({
        //   path: 'gPRegistrationID',
        //   message:
        //     'The BCHC neurodevelopmental pathway service can only accept referrals for children registered with Birmingham GPs or who are resident in the Birmingham area if they are not registered with a GP. Please encourage the parent to register their child with their local GP.',
        // });
      } else {
        return this.createError({
          path: 'gPRegistrationID',
          message: 'This information is required',
        });
      }
    },
  }),
  gPPracticeID: yup.number().test({
    test: function (value) {
      if (this.parent.gPRegistrationID === 1 && !value) {
        return this.createError({
          path: 'gPPracticeID',
          message: 'This information is required',
        });
      } else {
        return true;
      }
    },
  }),

  inSchool: stringRequired('Please confirm school setting'),
  homeEducation: stringRequired('Please confirm home education'),
  schoolID: yup.number().test({
    test: function (value) {
      if (this.parent.inSchool === 'Yes' && !value) {
        return this.createError({
          path: 'schoolID',
          message: 'This information is required',
        });
      } else {
        return true;
      }
    },
  }),
  schoolNameOther: yup.string().test({
    test: function (value) {
      if (this.parent.schoolID === 514 && !value) {
        return this.createError({
          path: 'schoolNameOther',
          message: 'Selected Other School. Please provide a school name',
        });
      } else {
        return true;
      }
    },
  }),

  schoolNurseryAddress: yup.string().test({
    test: function (value) {
      if (this.parent.schoolID === 514 && !value) {
        return this.createError({
          path: 'schoolNurseryAddress',
          message: 'Selected Other School. Please provide a school address',
        });
      } else {
        return true;
      }
    },
  }),

  schoolNurseryContactPerson: nullableStringMax('Incorrect format', 500),
  schoolNurseryContactPersonTel: nullableTelephone(
    'Please enter a valid telephone',
  ),
  schoolEmail: nullableEmail('Please enter a valid email address'),
  schoolTelephone: nullableTelephone('Please enter a valid telephone'),
});
