import * as yup from 'yup';

const re = /^0\d{8,16}$/;

export default function nullableTelephone(label) {
  return yup
    .string()
    .test(
      'Invalid telephone number',
      'Please enter a valid telephone',
      (value) => {
        const valueClean = value ? value.replace(/\s/g, '') : null;
        if (re.test(valueClean)) {
          return true;
        } else if (valueClean === null) {
          return true;
        } else {
          return false;
        }
      },
    )
    .label(label)
    .nullable()
    .typeError(label);
}
