import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { Button, Row, Col, ProgressBar, Card } from 'react-bootstrap';
import InformationIcon from '../../assets/icons/info-bold.svg';
import OpeningPage from '../base/openingPage.js';
import { generatePDFQuestions } from '../../utils/sendRequest.js';

export default function Wizard({
  children,
  initialValues,
  onSubmit,
  checkBhamPostCodes,
}) {
  const [stepNumber, setStepNumber] = useState(0);
  const steps = React.Children.toArray(children);
  const [snapshot, setSnapshot] = useState(initialValues);
  const step = steps[stepNumber];
  const totalSteps = steps.length;
  const isLastStep = stepNumber === totalSteps - 1;
  const [progress, setProgress] = useState(0);
  const [bhamPostCodeError, setBhamPostCodeError] = useState(false);
  const [formGuidance, setFormGuidance] = useState(true);
  const [pdfQuestionsProcessing, setPdfQuestionsProcessing] = useState(false);
  const [pdfQuestionsFailed, setPdfQuestionsFailed] = useState(false);

  const wizardSteps = [
    { id: 0, description: '1. Referrer' },
    { id: 1, description: '2. Patient Detail' },
    { id: 2, description: '3. Risk' },
    { id: 3, description: '4. Referral Reason' },
  ];
  const next = (values) => {
    setSnapshot(values);
    setStepNumber(Math.min(stepNumber + 1, totalSteps - 1));
    setProgress(progress + 25);
  };
  const previous = (values, touched) => {
    //pass in touched from next button, to allow reset of touched values and remove error messages
    touched({});
    setBhamPostCodeError(false);
    setSnapshot(values);
    setStepNumber(Math.max(stepNumber - 1, 0));
    setProgress(progress - 25);
  };
  const handleSubmit = async (values, bag) => {
    if (step.props.onSubmit) {
      await step.props.onSubmit(values, bag);
    }
    if (isLastStep) {
      setProgress(progress + 25);
      return onSubmit(values, bag);
    } else {
      if (values.gPRegistrationID === 3 && stepNumber === 1) {
        if (values.childAddressPostcode.substring(0, 1).toUpperCase() !== 'B') {
          setBhamPostCodeError(true);
        } else {
          const isBhamPostCode = await checkBhamPostCodes(
            values.childAddressPostcode.toUpperCase().replace(/\s/g, ''),
          );

          if (isBhamPostCode.postcodeExists) {
            setBhamPostCodeError(false);
            bag.setTouched({});
            next(values);
          } else {
            setBhamPostCodeError(true);
          }
        }
      } else {
        setBhamPostCodeError(false);
        bag.setTouched({});
        next(values);
      }
    }
  };

  const pdfQuestionsDownload = async () => {
    setPdfQuestionsProcessing(true);

    try {
      const resPdf = await generatePDFQuestions();

      const file = new Blob([resPdf], { type: 'application/pdf' });
      //IE fix
      // window navigator - detect browser
      //msSaveOrOpenBlob ie method to save a file in a browser
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(file);
        return;
      }

      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = fileURL;
      link.download = `NDP-Referral-Questions.pdf`;
      link.click();
      setTimeout(() => {
        URL.revokeObjectURL(link);
        setPdfQuestionsProcessing(false);
        setPdfQuestionsFailed(null);
      }, 100);
    } catch (error) {
      setPdfQuestionsProcessing(false);
      setPdfQuestionsFailed(error);
    }
  };

  if (formGuidance) {
    return (
      <Row>
        <Col xs={12} sm={12} md={12}>
          {pdfQuestionsFailed && (
            <Card bg="danger" text="light" className="mb-3">
              <Card.Header>Error</Card.Header>
              <Card.Body>
                {`There is a problem - error completing request - download questions pdf.`}
              </Card.Body>
            </Card>
          )}
          <OpeningPage
            pdfQuestionsDownload={pdfQuestionsDownload}
            pdfQuestionsProcessing={pdfQuestionsProcessing}
          />
          <div className="mt-2 mb-2">
            <Button
              variant="success"
              type="button"
              disabled={pdfQuestionsProcessing}
              onClick={() => setFormGuidance(false)}
            >
              Proceed to referral form
            </Button>
          </div>
        </Col>
      </Row>
    );
  } else {
    return (
      <Row>
        <Col xs={12} sm={12} md={3}>
          {wizardSteps.map((wizard) => (
            <div key={wizard.id}>
              <Button
                variant={'link'}
                disabled={stepNumber !== wizard.id}
                size="sm"
              >
                {wizard.description}
              </Button>
            </div>
          ))}
          <div className="mt-3">
            <h4>Help</h4>
            <img
              src={InformationIcon}
              alt="information-icon"
              className="icon-size"
            />{' '}
            Hover or click the information icon for guidance on completing the
            form.
          </div>
        </Col>
        <Col xs={12} sm={12} md={9}>
          <h1 className="mt-2 mb-2">
            Neurodevelopmental Pathway: Referral Form
          </h1>
          <Formik
            initialValues={snapshot}
            onSubmit={handleSubmit}
            validationSchema={step.props.validationSchema}
          >
            {(formik) => (
              <Form noValidate autoComplete="off">
                <p className="mt-2">
                  Step {stepNumber + 1} of {totalSteps}
                </p>
                <ProgressBar className="mt-2 mb-2" now={progress} />
                {step}
                {/* Birmingham address check error */}
                {bhamPostCodeError && (
                  <p className="customError">
                    The BCHC neurodevelopmental pathway service can only accept
                    referrals for children registered with Birmingham GPs or who
                    are resident in the Birmingham area if they are not
                    registered with a GP. Please encourage the parent to
                    register their child with their local GP.
                  </p>
                )}
                <div className="pagination-wizard">
                  {stepNumber > 0 && (
                    <Button
                      onClick={() => previous(formik.values, formik.setTouched)}
                      variant="light"
                      type="button"
                    >
                      Back
                    </Button>
                  )}
                  <div>
                    <Button
                      disabled={formik.isSubmitting}
                      variant="success"
                      type="submit"
                    >
                      {isLastStep ? 'Submit' : 'Next'}
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    );
  }
}
