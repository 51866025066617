import * as yup from 'yup';

import {
  intRequired,
  nullableString,
  arrayContains,
  stringRequired,
} from '../fields/index.js';

export default yup.object().shape({
  socialInteractionEyeID: intRequired('This information is required'),
  socialInteractionGesturesID: intRequired('This information is required'),
  socialInteractionEmotionsID: intRequired('This information is required'),
  restrictiveBehaviourInterestID: intRequired('This information is required'),
  restrictiveBehaviourUnusualID: intRequired('This information is required'),
  restrictiveBehaviourRoutineID: intRequired('This information is required'),
  restrictiveBehaviourDistressID: intRequired('This information is required'),
  communicationUnderstandID: intRequired('This information is required'),
  communicationSpokenID: intRequired('This information is required'),
  communicationFollowsID: intRequired('This information is required'),
  communicationExpressionsID: intRequired('This information is required'),
  attentionSelfID: intRequired('This information is required'),
  attentionLedID: intRequired('Attention led is required'),
  attentionLevelID: intRequired('This information is required'),
  sensoryLoudID: intRequired('This information is required'),
  sensoryTasteID: intRequired('This information is required'),
  learningBehindID: intRequired('This information is required'),
  learningSettledID: intRequired('This information is required'),
  visionPassedID: intRequired('This information is required'),
  visionConcernID: intRequired('This information is required'),
  hearingPassedID: intRequired('This information is required'),
  hearingConcernID: intRequired('This information is required'),
  motorPassedID: intRequired('This information is required'),
  motorConcernID: intRequired('This information is required'),
  behaviourID: intRequired('This information is required'),
  mannerisms: arrayContains('This information is required'),
  mannerismsComment: yup.string().when('mannerisms', {
    is: (mannerisms) =>
      // 7 is other
      mannerisms && mannerisms.includes('7'),
    then: stringRequired('Selected Other. This information is required'),
  }),

  motorLostID: intRequired('This information is required'),
  motorLostComment: yup.string().test({
    test: function (value) {
      if (this.parent.motorLostID === 1 && !value) {
        return this.createError({
          path: 'motorLostComment',
          message: 'Select YES. This information is required',
        });
      } else {
        return true;
      }
    },
  }),
  birthID: intRequired('This information is required'),
  learningDelayID: intRequired('This information is required'),
  learningDelayComment: yup.string().test({
    test: function (value) {
      if (this.parent.learningDelayID === 1 && !value) {
        return this.createError({
          path: 'learningDelayComment',
          message: 'Select YES. This information is required',
        });
      } else {
        return true;
      }
    },
  }),
  autFamilyID: intRequired('This information is required'),
  autFamilyComment: yup.string().test({
    test: function (value) {
      if (this.parent.autFamilyID === 1 && !value) {
        return this.createError({
          path: 'autFamilyComment',
          message: 'Select YES. This information is required',
        });
      } else {
        return true;
      }
    },
  }),
  additionalMedicalID: intRequired('This information is required'),
  additionalMedicalComment: yup.string().test({
    test: function (value) {
      if (this.parent.additionalMedicalID === 1 && !value) {
        return this.createError({
          path: 'additionalMedicalComment',
          message: 'Select YES. This information is required',
        });
      } else {
        return true;
      }
    },
  }),

  additionalInformation: nullableString('Incorrect format'),
});
