import InputField from '../formFields/inputField.js';
import SelectFieldCode from '../formFields/selectFieldCode.js';
import SelectField from '../formFields/selectField.js';
import RadioField from '../formFields/radioField.js';
import AsyncSelectField from '../formFields/asyncSelectField.js';
import { Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import CustomFieldError from '../formFields/customFieldError.js';
import CustomFieldErrorLink from '../formFields/customFieldErrorLink.js';
import InformationIcon from '../../assets/icons/info-bold.svg';
import PostcodeSearch from '../formFields/address/postcodeSearch.js';
import DatePickerField from '../formFields/datepickerField.js';

import { useEffect } from 'react';

export default function Patient(props) {
  const { values, errors, touched } = useFormikContext();

  useEffect(() => {
    window.scrollTo(0, 20); // 20 prevent scrolling issues, not firing on build
  }, []);
  const popover = (
    <Popover id="popover-contained">
      <Popover.Header as="h3">
        Severe / profound learning disability includes
      </Popover.Header>
      <Popover.Body>
        <ul>
          <li>Significant intellectual or cognitive impairment </li>
          <li>
            Significant difficulties in communication - little or no speech
          </li>
          <li>
            Requires significant support in personal care such as dressing /
            toiletting / feeding
          </li>
        </ul>
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <Form.Group controlId="severeLearningDisability" className="mb-2">
        <OverlayTrigger
          trigger={['hover', 'click', 'focus']}
          placement="top"
          overlay={popover}
        >
          <Form.Check.Label className="tool-hover mb-2">
            Does the child have a severe learning disability?{' '}
            <img
              src={InformationIcon}
              alt="information-icon"
              className="icon-size"
            />
          </Form.Check.Label>
        </OverlayTrigger>
        <RadioField
          name="severeLearningDisability"
          value="Yes"
          label="Yes"
          type="radio"
          disabled={props.processing}
        />
        <RadioField
          name="severeLearningDisability"
          value="No"
          label="No"
          type="radio"
          disabled={props.processing}
        />
      </Form.Group>

      <CustomFieldErrorLink
        name={'severeLearningDisability'}
        includeLink={true}
        linkText={
          'Please contact Forward Thinking Birmingham Learning Disability Service for further advice and guidance.'
        }
        link={'https://forwardthinkingbirmingham.nhs.uk/'}
      />
      <InputField
        name="childFirstName"
        type="text"
        label="Child's First Name*"
        disabled={props.processing}
      />
      <InputField
        name="childSurname"
        disabled={props.processing}
        type="text"
        label="Child's Last Name*"
      />
      <DatePickerField
        className="mt-2"
        disabled={props.processing}
        label={'Child Date of Birth* (For example 15/03/2010)'}
        name="childDOB"
        placeholderText="DD/MM/YYYY"
      />

      <PostcodeSearch
        name="childPostCodeSearch"
        inputLabel={'Child Address'}
        addressFields={'childAddress'}
        label={'Child address postcode search'}
        placeholder={'Please enter a valid postcode to search'}
      />
      <InputField
        name="nhsNumber"
        disabled={props.processing}
        type="text"
        label="NHS No. (if known)"
      />
      <InputField
        name="parentName"
        disabled={props.processing}
        type="text"
        label="Parent Name*"
      />
      <InputField
        name="parentEmail"
        disabled={props.processing}
        type="text"
        label="Parent / Carer’s email address (if known)"
      />
      <InputField
        name="parentTelephone"
        disabled={props.processing}
        type="text"
        label="Parent / Carer’s phone number (if known)"
      />
      <p>
        <strong>Parent Address</strong>
      </p>
      <Form.Group controlId="parentAddressDiffChild" className="mb-2">
        <Form.Check.Label className="tool-hover mb-2">
          Parent address different to child?
        </Form.Check.Label>
        <RadioField
          name="parentAddressDiffChild"
          value="Yes"
          label="Yes"
          type="radio"
          disabled={props.processing}
        />
        <RadioField
          name="parentAddressDiffChild"
          value="No"
          label="No"
          type="radio"
          disabled={props.processing}
        />
      </Form.Group>
      <CustomFieldError name={'parentAddressDiffChild'} />
      <PostcodeSearch
        name="parentPostCodeSearch"
        addressFields={'parentAddress'}
        inputLabel={'Parent Address'}
        label={'Parent address postcode search'}
        placeholder={'Please enter a valid postcode to search'}
        parentAddressDiffChild={values.parentAddressDiffChild === 'No'}
      />
      <Form.Group controlId="interpreter" className="mb-2">
        <Form.Check.Label className="mb-2">
          Interpreter required*
        </Form.Check.Label>
        <RadioField
          name="interpreter"
          value="Yes"
          label="Yes"
          type="radio"
          disabled={props.processing}
        />
        <RadioField
          name="interpreter"
          value="No"
          label="No"
          type="radio"
          disabled={props.processing}
        />
      </Form.Group>
      <SelectFieldCode
        name={'interpreterGenLanguageCode'}
        label={'Interpreter Language'}
        data={props.genLanguages}
        disabled={props.processing || values.interpreter === 'No'}
      />

      <Form.Group controlId="inSchool" className="mb-2">
        <Form.Check.Label className="mb-2">
          Is child in school/nursery setting
        </Form.Check.Label>
        <RadioField
          name="inSchool"
          value="Yes"
          label="Yes"
          type="radio"
          disabled={props.processing}
        />
        <RadioField
          name="inSchool"
          data-testid="inSchoolNo"
          value="No"
          label="No"
          type="radio"
          disabled={props.processing}
        />
      </Form.Group>
      <Form.Group controlId="homeEducation" className="mb-2">
        <Form.Check.Label className="mb-2">
          Is child in home education
        </Form.Check.Label>
        <RadioField
          name="homeEducation"
          value="Yes"
          label="Yes"
          type="radio"
          disabled={props.processing || values.inSchool === 'Yes'}
        />
        <RadioField
          name="homeEducation"
          value="No"
          data-testid="homeEducationNo"
          label="No"
          type="radio"
          disabled={props.processing || values.inSchool === 'Yes'}
        />
      </Form.Group>
      <AsyncSelectField
        name={'schoolID'}
        label="Search for child School/Nursery (If your child’s school/nursery is not in the search list please select “Other” from the list and enter their school/nursery address details below)"
        popover={true}
        popoverContent={
          'If your child’s school/nursery is not in the search list please select “Other” from the list and enter their school/nursery address details below.'
        }
        data={props.school}
        optionCode={'SchoolCodeURN'}
        optionName={'SchoolName'}
        optionValue={'Id'}
        placeholder={'Search for child School/Nursery'}
        disabled={props.processing || values.inSchool === 'No'}
      />
      <InputField
        name="schoolNameOther"
        type="text"
        label="School/Nursery Name Other"
        disabled={
          props.processing ||
          values.inSchool === 'No' ||
          (values.schoolID && values.schoolID !== 514) //Other
        }
      />
      <InputField
        name="schoolNurseryAddress"
        type="text"
        label="School/Nursery Address"
        disabled={
          props.processing ||
          values.inSchool === 'No' ||
          (values.schoolID && values.schoolID !== 514) //Other
        }
      />
      <InputField
        name="schoolNurseryContactPerson"
        disabled={props.processing || values.inSchool === 'No'}
        type="text"
        label="School/Nursery Contact Person"
      />
      <InputField
        name="schoolNurseryContactPersonTel"
        disabled={props.processing || values.inSchool === 'No'}
        type="text"
        label="School/Nursery Contact Person Telephone"
      />
      <InputField
        name="schoolEmail"
        type="text"
        label="School/Nursery Email"
        disabled={props.processing || values.inSchool === 'No'}
      />
      <InputField
        name="schoolTelephone"
        disabled={
          props.processing ||
          values.inSchool === 'No' ||
          (values.schoolID && values.schoolID !== 514) //Other
        }
        type="text"
        label="School/Nursery Telephone"
      />

      <SelectField
        name={'gPRegistrationID'}
        label={'Is child registered with a GP?*'}
        data={props.gPRegistration}
        disabled={props.processing}
      />
      <AsyncSelectField
        name={'gPPracticeID'}
        label="Search for child’s Birmingham GP by practice code or practice name"
        data={props.gPPractice}
        popover={false}
        // popoverContent={
        //   'If the GP you are searching for is not shown, please contact 0121 *** ****.'
        // }
        optionCode={'GPPracticeCode_Current'}
        optionName={'GPPracticeName_Current'}
        optionValue={'Id'}
        placeholder={
          'Search for child’s Birmingham GP by practice code or practice name'
        }
        disabled={props.processing || values.gPRegistrationID !== 1}
      />
      {Object.keys(touched).length > 0 && Object.keys(errors).length > 0 && (
        <p className="customError mt-4">
          Please complete all required fields (*)
        </p>
      )}
    </>
  );
}
