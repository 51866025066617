import InputField from '../formFields/inputField.js';
import SelectField from '../formFields/selectField.js';
import RadioField from '../formFields/radioField.js';
import CheckBoxField from '../formFields/checkBoxField.js';
import CustomFieldError from '../formFields/customFieldError.js';
import TextAreaField from '../formFields/textAreaField.js';
import DatePickerField from '../formFields/datepickerField.js';
import { Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import PostcodeSearch from '../formFields/address/postcodeSearch.js';
import { useEffect } from 'react';

export default function Referrer(props) {
  const { values, errors, touched } = useFormikContext();

  useEffect(() => {
    window.scrollTo(0, 5);
  }, []);
  return (
    <>
      <InputField
        name="referrerName"
        disabled={props.processing}
        type="text"
        label="Referrer Name*"
      />
      <SelectField
        name={'parentalResponibilityID'}
        label={'Person with Parental Responsibility*'}
        data={props.parentalResponsibility}
        disabled={props.processing}
      />
      <SelectField
        name={'referrerTitleID'}
        label={'Referrer Title/Position*'}
        data={props.referrerTitle}
        disabled={props.processing}
      />
      <InputField
        name="referrerEmail"
        disabled={props.processing}
        type="email"
        label="Referrer Email*"
      />
      <InputField
        name="referrerTelephone"
        disabled={props.processing}
        type="text"
        label="Referrer Telephone*"
      />
      <InputField
        name="referrerMobile"
        disabled={props.processing}
        type="text"
        label="Referrer Mobile"
      />
      <PostcodeSearch
        name="referrerPostCodeSearch"
        addressFields={'referrerAddress'}
        label={'Referrer address postcode search'}
        inputLabel={'Referrer Address'}
        placeholder={'Please enter a valid postcode to search'}
      />
      <Form.Group controlId="referrerConsent">
        <Form.Check.Label className="mb-2">
          I have discussed this referral with parents/carers with parental
          responsibility and they give consent for this referral to be made.
          This includes for the child's school to be sent a questionnaire to be
          completed to support the assessment process and where appropriate for
          liaison to take place with Birmingham City Council to verify referrals
          to specialist education services or health and social care.
        </Form.Check.Label>
        <RadioField
          name="referrerConsent"
          value="Yes"
          label="Yes"
          type="radio"
          disabled={props.processing}
        />
        <RadioField
          name="referrerConsent"
          value="No"
          label="No"
          type="radio"
          disabled={props.processing}
        />
      </Form.Group>
      <CustomFieldError name={'referrerConsent'} />
      <DatePickerField
        className="mt-2"
        disabled={props.processing}
        label={'Date* (For example 15/09/2022)'}
        placeholderText="DD/MM/YYYY"
        name="referrerConsentDate"
      />
      <Form.Group controlId="referrerNdtContactConsent" className="mb-3">
        <Form.Check.Label className="mb-2">
          Does the person with parental responsibility give permission for the
          Neurodevelopmental Team to contact the following services for further
          information?
        </Form.Check.Label>
        <RadioField
          name="referrerNdtContactConsent"
          value="Yes"
          label="Yes"
          type="radio"
          disabled={props.processing}
        />
        <RadioField
          name="referrerNdtContactConsent"
          value="No"
          label="No"
          type="radio"
          disabled={props.processing}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        {props.ndtContactReference.map((item, index) => (
          <CheckBoxField
            key={index}
            name={`referrerNdtContact`}
            value={item.Id}
            label={item.Description}
            id={item.Description}
            type="checkbox"
            disabled={
              props.processing ||
              values.referrerNdtContactConsent === 'No' ||
              (item.Id === 9 &&
                values.referrerNdtContact.length > 0 &&
                !values.referrerNdtContact.includes('9')) ||
              (item.Id !== 9 &&
                values.referrerNdtContact.length > 0 &&
                values.referrerNdtContact.includes('9'))
            }
          />
        ))}
        <CustomFieldError name={'referrerNdtContact'} />
        <TextAreaField
          name="referrerNdtContactOther"
          disabled={
            props.processing || !values.referrerNdtContact.includes('10')
          } //10 is other
          as="textarea"
          rows={3}
          label="Other services not listed"
        />
        {Object.keys(touched).length > 0 && Object.keys(errors).length > 0 && (
          <p className="customError mt-4">
            Please complete all required fields (*)
          </p>
        )}
      </Form.Group>
    </>
  );
}
