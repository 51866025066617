import * as yup from 'yup';
import moment from 'moment';

import {
  stringMinMaxRequired,
  nullableStringMax,
  arrayContains,
  intRequired,
  stringRequired,
  stringRequiredWhenValue,
  email,
  nullableMobile,
  detailsArray,
  postcode,
} from '../fields/index.js';

export default yup.object().shape({
  referrerName: stringMinMaxRequired('This information is required', 1, 500),
  parentalResponibilityID: intRequired('This information is required'),
  referrerTitleID: intRequired('This information is required'),
  referrerEmail: email(
    'This information is required. Please enter a valid email',
  ),

  referrerMobile: nullableMobile(
    'This information is required. Please enter a valid mobile',
  ),

  referrerTelephone: yup.string().test({
    message: 'This information is required. Please enter a valid telephone',
    test: function (value) {
      const tel = /^0\d{8,16}$/;
      if (value) {
        const valueClean = value ? value.replace(/\s/g, '') : null;
        if (tel.test(valueClean)) {
          return true;
        } else {
          return false;
        }
      } else if (
        this.parent.referrerMobile === undefined ||
        this.parent.referrerMobile === '' ||
        this.parent.referrerMobile === null
      ) {
        return false;
      } else {
        return true;
      }
    },
  }),
  referrerAddressLine1: stringMinMaxRequired(
    'This information is required',
    1,
    500,
  ),
  referrerAddressLine2: stringMinMaxRequired(
    'This information is required',
    1,
    500,
  ),
  referrerAddressLine3: nullableStringMax('Incorrect format', 500),
  referrerAddressLine4: stringMinMaxRequired(
    'This information is required',
    1,
    500,
  ),
  referrerAddressPostcode: postcode(
    'This information is required. Please enter a valid postcode',
  ),
  referrerConsent: stringRequiredWhenValue(
    'Referrals can only be accepted with confirmation that a person with parental responsibility has consented to it.',
    'Yes',
  ),

  referrerConsentDate: yup
    .date()
    .test({
      message:
        'This information is required. Referrer consent date must be in the past',
      test: function (value) {
        const now = moment();
        if (value && moment(value).isSameOrBefore(now, 'day')) {
          return true;
        } else {
          return false;
        }
      },
    })
    .typeError(
      'This information is required. Referrer consent date must be in the past',
    ),

  referrerNdtContactConsent: stringRequired('This information is required'),
  referrerNdtContact: detailsArray(
    'referrerNdtContactConsent',
    arrayContains('This information is required'),
    'Yes',
  ),
  referrerNdtContactOther: yup.string().when('referrerNdtContact', {
    is: (referrerNdtContact) =>
      // 10 is other
      referrerNdtContact && referrerNdtContact.includes('10'),
    then: stringRequired('Selected Other.This information is required'),
  }),
});
