import * as yup from 'yup';

export default function arrayContains(label) {
  return yup
    .array()
    .min(1, 'This information is required')
    .label()
    .required(label)
    .typeError(label);
}
